/* MOBILE */
.mobile_servicios {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  padding: 10% 0 0 !important;
  margin: auto;
}

.mobile_servicios-img {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  height: max-content;
}

.mobile_serviciosTitle {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #da5a63;
}

.mobile_serviciosFlex {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 30px 0 30px;
}

.mobile_serviciosSubtitle {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.0025em;
  color: #ededed;
  margin-bottom: 29px;
}

.mobile_serviciosSubtitle span {
  color: #0091ea;
}

.mobile_serviciosText {
  margin: -8px 0 24px;
  text-align: justify;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  color: #EDEDED;
}

.mobile_serviciosLista li {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  color: #ededed;
  margin-left: 10px;
}

/* DESKTOP */

.servicios {
  width: 100%;
  max-width: 1440px;
  display: flex;
  padding: 10% 0 0 !important;
  margin: auto;
}

.servicios-img {
  width: 50%;
  position: relative;
  display: flex;
  align-items: flex-start;
  height: max-content;
}

.serviciosImagen {
  width: 100%;
  height: auto;
  position: relative;
}

.serviciosTrianguloL {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 60%;
}

.serviciosTrianguloR {
  width: 60%;
  position: absolute;
  bottom: 0px;
  right: 0;
}

.serviciosFlex {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 70px;
}

.serviciosTitle {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 86px;
  color: #da5a63;
}

.serviciosSubtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.0025em;
  color: #ededed;
  margin-bottom: 29px;
}

.serviciosSubtitle span {
  color: #0091ea;
}

.serviciosText {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.0025em;
  color: #ededed;
  margin-bottom: 24px;
}

.serviciosLista li {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.0025em;
  color: #ededed;
  margin-left: 48.75px;
}

.buttonContainer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  bottom: 0;
}

.serviciosLista_lista-icon {
  position: relative;
  top: 5px;
}

ul {
  list-style: none;
}

@media screen and (max-width: 1200px) {
  .serviciosFlex {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 2% 0 2%;
  }
}