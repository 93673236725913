/* MOBILE */
.mobile_bottom_triangle {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 65%;
  z-index: 5;
}

.mobile_sobreMi {
  width: 100%;
  height: 1072px;
  background-image: url("./Assets/back_sec_sobremi.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.mobile_facu {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  width: 340px;
  height: 470px;
}

.mobile_top_triangle {
  position: absolute;
  width: 30%;
  top: -5px;
  right: 0;
}

.mobile_sobreMi_content {
  padding: 2rem 1.5rem;
  position: absolute;
  width: 100%;
}

.mobile_sobreMi_content-title {
  color: #da5a63;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.mobile_sobreMi_content-title span {
  color: #0091ea;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #0091EA;
}

.mobile_sobreMi_content-description {
  margin: 1rem 0;
  width: 100%;
  color: #ededed;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0025em;
}

.mobile_sobreMi_content-certifications--title {
  color: #ededed;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 5px 0;
}

.mobile_sliderContainer {
  margin-top: 40px;
  width: 100%;
  position: relative;
}

/* DESKTOP */

.sobreMi_container {
  padding: 10% 0 0;
  margin: auto;
  overflow: hidden;
}

.sobreMi_stick {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  height: 1px;
  background-color: #767676;
  z-index: 9;
}

.sobreMi {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  height: 739px;
  background-image: url("./Assets/back_sec_sobremi.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.sobreMi_content {
  padding: 3rem 4rem 0;
  position: absolute;
}

.sobreMi_content-title {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 50px;
  color: #da5a63;
}

.sobreMi_content-title span {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 98px;
  line-height: 100px;
  color: #0091ea;
}

.sobreMi_content-description {
  width: 768px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.0025em;
  color: #ededed;
  margin: 2rem 0;
}

.sobreMi_content-description span {
  margin-top: 1rem;
  width: 768px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.0025em;
  color: #ededed;
}

.top_triangle {
  position: absolute;
  top: -6px;
  right: 0;
}

.bottom_triangle {
  position: absolute;
  bottom: 0px;
  right: 0;
  z-index: 5;
}

.facu {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sobreMi_content-certifications {
  width: 768px;
  display: flex;
  align-items: center;
}

.sobreMi_content-certifications span {
  width: 100%;
  background-color: #ededed;
  height: 2px;
}

.sobreMi_content-certifications--title {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ededed;
  padding: 0 5%;
}

.sliderContainer {
  margin-top: 35px;
  width: fit-content;
  position: absolute;
}

@media screen and (max-width: 425) {
  .sliderContainer {
    margin-top: 25px;
    width: fit-content;
    position: absolute;
  }
}