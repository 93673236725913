@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat&family=Source+Sans+Pro:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.totalContainer {
  background-color: #1e1e1e;
}

/* font-family: 'Bebas Neue', cursive;
font-family: 'Montserrat', sans-serif;
font-family: 'Source Sans Pro', sans-serif; */
