/* MOBILE */

.mobile_heroContainer {
  width: 100%;
  height: 91.4vh;
  background-image: url("./Assets/img-hero-filtro.jpg");
  background-size: cover;
  background-position: right;
}

.mobile_triangulo_blanco {
  width: 85%;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
}

.mobile_heroTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 55px;
  letter-spacing: 0.16em;
  color: #0091EA;
}

.mobile_heroTitle span {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 40px;
  color: #EDEDED;
  letter-spacing: .1px;
}

.mobile_heroSubtitle {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 128%;
  letter-spacing: 0.18em;
  color: #0091EA;
}

.mobile_flex {
  width: 100%;
  height: 100%;
  color: #0091ea;
  font-weight: 600;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 0 0 1.5rem 9%;
}

/* DESKTOP */

.heroContainer {
  width: 100%;
  height: 86vh;
  background-image: url("./Assets/img-hero-filtro.jpg");
  background-size: cover;
  background-position: center;
}

.flex {
  width: 100%;
  height: 100%;
  color: #0091ea;
  font-weight: 600;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 69px;
  position: relative;
}

.heroTitle {
  font-family: "Montserrat";
  font-size: 96px;
  line-height: 110px;
  letter-spacing: 0.16em;
}

.heroTitle span {
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 130px;
  line-height: 117px;
  color: #ededed;
}

.heroSubtitle {
  font-family: "Source Sans Pro";
  font-size: 20px;
  line-height: 128%;
  letter-spacing: 0.18em;
}

.triangles {
  position: absolute;
  width: 692px;
  bottom: 0;
  right: 112.94px;
}

.triangulo_rojo {
  width: 14%;
  height: auto;
  position: absolute;
  top: -15px;
  left: 0;
}

.triangulo_blanco {
  width: 28%;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 425px) {
  .mobile_heroContainer {
    background-image: url("./Assets/_imgfiltro_home_movil.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 91.4vh;
  }
}
@media screen and (max-width: 348px) {
  .mobile_flex {
    padding: 0 0 1.5rem 5%;
  }

  .mobile_lista_descuento {
    font-size: 36px;
    line-height: 75px;
}
}