/* MOBILE */
.mobile_slider {
  height: 215px;
  width: 320px;
}

.mobile_slide img {
  border-radius: 30px;
  width: 100%;
  height: 100%;
  border: 2px solid #0091EA;
  border-radius: 28.9785px;
}





/* DESKTOP */

.slider {
  width: 769px;
  height: max-content;
}

.slide img {
  border-radius: 30px;
  width: 100%;
  height: 100%;
  border: 2px solid #0091EA;
  border-radius: 28.9785px;
}

.swiper-slide,
swiper-slide {
  flex-shrink: 0;
  width: 256px !important;
  height: 176px !important;
  padding: 0 20px;
  position: relative;
  transition-property: transform;
  display: block;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}


@media screen and (max-width: 425px) {

  .swiper-slide,
  swiper-slide {
    flex-shrink: 0;
    width: 100% !important;
    height: 100% !important;
    position: relative;
    transition-property: transform;
    display: block;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
  }
}

@media screen and (max-width: 370px) {
  .mobile_slide img {
    border-radius: 30px;
    width: 90%;
    height: 90%;
    border: 2px solid #0091EA;
    border-radius: 28.9785px;
  }

  .swiper-slide,
  swiper-slide {
    flex-shrink: 0;
    width: 100% !important;
    height: 100% !important;
    position: relative;
    transition-property: transform;
    padding: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .swiper,
  swiper-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
  }
}