/* MOBILE */

.mobile_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 70px auto 0;
}

.mobile_oferta {
  display: flex;
  margin: 40px 0 30px;
  justify-content: flex-end;
}

.mobile_imagen_container {
  position: relative;
  width: 310px;
  height: 120px;
}

.mobile_imagen {
  width: 100%;
  height: 100%;
}

.mobile_imagen_title {
  position: absolute;
  top: 12px;
  right: 13px;
  text-align: right;
  color: #ffffff;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 36px;
  display: flex;
  align-items: center;
}

.mobile_imagen_subtitle {
  bottom: 5px;
  position: absolute;
  right: 13px;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #151515;
}

.mobile_title {
  text-align: center;
  color: #ededed;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_title span {
  color: #da5a63;
  margin-left: 8px;
}

.mobile_sub-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 8%;
  margin: 50px 0 0;
}

.mobile_lista {
  list-style: none;
}

.mobile_lista_descuento {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 80px;
  transition: all .45s ease-in-out;
  cursor: pointer;
}

.mobile_lista_descuento-item {
  text-decoration: none;
  color: #0091EA;
}

/* DESKTOP */

.container {
  width: 100%;
  max-width: 1440px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: 400;
  margin-top: 100px;
}

.title {
  font-size: 48px;
  line-height: 58px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ededed;
}

.title span {
  color: #da5a63;
  margin-left: 8px;
}

.sub-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 0 0%;
}

.lista {
  list-style: none;
}

.lista_descuento {
  transition: all .45s ease-in-out;
}

.lista_descuento-item {
  transition: all .45s ease-in-out;
  color: #0091ea;
  font-size: 72px;
  line-height: 86px;
  text-decoration: none;
  cursor: pointer;

}

.lista_descuento-item:hover {
  color: #da5a63;
  text-decoration: none;
}

.lista_descuento:hover {
  transform: translateX(30px);
}

.imagen_container {
  position: relative;
  height: 203px;
}

.imagen {
  width: 573px;
  height: 203px;
}

.imagen_title {
  font-size: 70px;
  position: absolute;
  top: 18px;
  right: 25px;
  line-height: 60px;
  text-align: right;
  color: #ffffff;
}

.imagen_subtitle {
  bottom: 18px;
  position: absolute;
  right: 25px;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #151515;
}

@media screen and (max-width: 1440px) {
  .lista {
    margin-left: 72px;
    font-size: 72px;
    color: #0091ea;
    list-style: none;
    line-height: 86px;
  }
}

@media screen and (max-width: 1190px) {
  .lista {
    margin-left: 52px;
    font-size: 60px;
    color: #0091ea;
    list-style: none;
    line-height: 65px;
  }

  .imagen_title {
    font-size: 52px;
    position: absolute;
    top: 18px;
    right: 75px;
    line-height: 56px;
    text-align: right;
    color: #ffffff;
  }

  .imagen {
    width: 448px;
    height: 203px;
  }
}