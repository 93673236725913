/* MOBILE */
.mobile_btn {
  background: linear-gradient(180deg, #0091ea 100%, #80cfff 0%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  height: 48px;
  width: 142px;
  border: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-decoration: none;
  position: relative;
}

.mobile_btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(180deg, #0091ea 0%, #80cfff 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  opacity: 0;
  transition: opacity .3s;
}

.mobile_btn:hover::before {
  opacity: 1;
}

.mobile_btn>* {
  position: relative;
}

.mobile_btn_content {
  color: #151515;
  padding-bottom: 2px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #151515;
}

.mobile_btn_icon {
  color: #151515;
  width: 15px;
  height: 17px;
}

/* DESKTOP */

.btn {
  background: linear-gradient(180deg, #0091ea 100%, #80cfff 0%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  height: 48px;
  width: 240px;
  border: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-decoration: none;
  position: relative;
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(180deg, #0091ea 0%, #80cfff 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  opacity: 0;
  transition: opacity .3s;
}

.btn:hover::before {
  opacity: 1;
}

.btn>* {
  position: relative;
}

.btn_content {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #151515;
  padding-bottom: 2px;
}

.btn_icon {
  color: #151515;
  width: 15px;
  height: 17px;
}