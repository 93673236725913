/* MOBILE */

.mobile_footer {
  width: 100%;
}

.mobile_footer_top {
  margin: 4rem auto 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile_footer_top-socialNetworks--socialNetworks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  height: 44.2px;
}

.mobile_footer_top-socialNetworks--socialNetworks a {
  background: transparent;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
}

.mobile_footer_top-socialNetworks--socialNetworks a>* {
  position: relative;
}

/* DESKTOP */
.footer_top {
  max-width: 1440px;
  margin: 4rem auto 4rem;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.2fr max-content 0.2fr;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "footer_top-logo footer_top-socialNetworks footer_top-nav";
}

.footer_top-logo {
  grid-area: footer_top-logo;
  display: flex;
}

.footer_top-socialNetworks {
  grid-area: footer_top-socialNetworks;
}

.footer_top-socialNetworks--title {
  display: flex;
  justify-content: center;
  margin: 20px 0 30px 0;
}

.footer_top-socialNetworks--socialNetworks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 380px;
  height: 44.2px;
}

.footer_top-socialNetworks--title---title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.18em;
  color: #ededed;
  text-shadow: 0px 4px 4px rgba(21, 21, 21, 0.71);
}

.footer_top-nav {
  grid-area: footer_top-nav;
  display: flex;
  flex-direction: column;
}

.footer_top-logo--img {
  width: 157px;
  height: 116px;
}

.footer_top-nav--anchor {
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 128%;
  text-align: end;
  letter-spacing: 0.12em;
  color: #ededed;
  margin: 10px 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.footer_top-nav--anchor:hover {
  color: #da5a63;
}

.footer_stick {
  width: 100%;
  height: 1px;
  background-color: #0091ea;
}

.footer_bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.footer_bottom-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ededed;
  text-shadow: 0px 4px 4px rgba(21, 21, 21, 0.71);
}

.footer_top-socialNetworks--socialNetworks a {
  background: transparent;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
}

.footer_top-socialNetworks--socialNetworks a>* {
  position: relative;
}

.facebook::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  transition: opacity .3s;
  opacity: 0;
  background: linear-gradient(224.9deg,
      #4676ed 7.43%,
      #436de4 23.17%,
      #3c55cd 48.77%,
      #302ea8 80.87%,
      #2b1e99 92.33%);
}


.instagram::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  transition: opacity .3s;
  opacity: 0;
  background: linear-gradient(225deg,
      #fbe18a -1.32%,
      #fcbb45 25.75%,
      #f75274 47.47%,
      #d53692 66.61%,
      #8f39ce 94.63%,
      #5b4fe9 128.5%);
}

.tiktok::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  transition: opacity .3s;
  opacity: 0;
  background: linear-gradient(135deg,
      #22004a 7.66%,
      #020003 89%,
      #000000 92.7%);
}

.youtube::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  transition: opacity .3s;
  opacity: 0;
  background: linear-gradient(225deg,
      #ff0000 7.44%,
      #850000 92.48%);
}

.facebook:hover::before {
  opacity: 1;
}

.instagram:hover::before {
  opacity: 1;
}

.tiktok:hover::before {
  opacity: 1;
}

.youtube:hover::before {
  opacity: 1;
}

@media screen and (max-width: 1440px) {
  .footer_top-logo {
    grid-area: footer_top-logo;
    display: flex;
    justify-content: center;
  }

  .footer_top-logo--img {
    width: 157px;
    height: 116px;
    margin-left: 3rem;
  }

  .footer_top-nav {
    grid-area: footer_top-nav;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }

  .footer_top-nav--anchor {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 128%;
    text-align: center;
    letter-spacing: 0.12em;
    color: #ededed;
    margin: 10px 0;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
}