/* MOBILE */
.mobile_contacto {
  position: relative;
  width: 100%;
  height: 426px;
  display: flex;
  justify-content: center;
}

.mobile_contacto_background {
  width: 100%;
  height: 448px;
  position: absolute;
}

.mobile_contacto_background-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.mobile_contacto_content {
  position: relative;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile_contacto_content-title {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(21, 21, 21, 0.71);
  color: #ededed;
}

.mobile_contacto_content-description {
  color: #ededed;
  margin: 15px 0 20px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(21, 21, 21, 0.71);
}

.mobile_btnL {
  background: linear-gradient(180deg, #0091ea 100%, #80cfff 0%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  height: 48px;
  width: 188px;
  border: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-decoration: none;
  position: relative;
}

.mobile_btnL::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(180deg, #0091ea 0%, #80cfff 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  opacity: 0;
  transition: opacity .3s;
}

.mobile_btnL:hover::before {
  opacity: 1;
}

.mobile_btnL>* {
  position: relative;
}

.mobile_btnL_content {
  color: #151515;
  padding-bottom: 2px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #151515;
}

.mobile_btnL_icon {
  color: #151515;
  width: 15px;
  height: 17px;
}

/* DESKTOP */

.contacto_container {
  padding: 10% 0 0;
  margin-top: 3rem;
}

.contacto {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  height: 448px;
}

.contacto_background {
  width: 100%;
  height: 448px;
  position: absolute;
}

.contacto_background-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.contacto_content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contacto_content-title {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 86px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ededed;
  text-shadow: 0px 4px 4px rgba(21, 21, 21, 0.71);
}

.contacto_content-description {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ededed;
  text-shadow: 0px 4px 4px rgba(21, 21, 21, 0.71);
  margin: 0 0 40px;
}